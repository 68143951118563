.budget-edit-container {
  &.page-wrapper.single-view {
    background-color: $table_hover !important;
  }

  .btn-bar-left {
    h3 {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 0px;
      margin-left: 10px;
    }
  }
  .btn-bar-right {
    .link {
      color: $color_subtitle;
      width: 20px;
      margin-right: 10px;
      padding: 0px;

      &:hover {
        color: $dark_gray;
        border: 1px solid transparent;
      }
    }

    .year-filter {
      font-weight: bold;
      font-size: 20px;
      display: flex;
      align-content: center;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  .table-container {
    table {
      th {
        text-align: right;

        &.selected {
          background-color: $yellow;
        }

        &.left {
          text-align: left;
          align-items: center;
          display: flex;
          i {
            width: 30px;
            color: $black;
            &:hover {
              color: $green;
            }
          }
        }
        b {
          color: $black;
        }
      }

      tr {
        text-align: right;
        height: 20px;

        &.name-row {
          text-align: left;
          background-color: $bg_gray;
          position: sticky;
          top: 31px;

          td {
            color: $black;
            font-size: 12px;

            .name-value {
              font-size: 16px;
              font-weight: 500;
              margin-right: 10px;
              color: $black;
              cursor: pointer;
              &:hover {
                text-decoration: underline;
              }

              a {
                color: $black;
                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }
        }

        &.plan-row {
          td {
            &.buget-item-row {
              color: $blue;
            }
          }
        }

        &.total-row {
          td {
            &.buget-item-row {
              font-weight: 700;
              color: $black;
            }
          }
        }

        &.selected {
          background-color: $light_blue;

          td {
            &.selected {
              background-color: $yellow;
              text-decoration: underline;

              input {
                background-color: $yellow;
              }
            }
          }
        }

        td.buget-item-row.selectedCol {
          background-color: $yellow;
          input {
            background-color: transparent;
          }
        }

        // TOTAL COL
        &:hover {
          td.month_13 {
            background-color: $light_blue_hover;
          }
        }

        &.selected {
          td.month_13 {
            background-color: $light_blue_hover;

            &.selected {
              background-color: $yellow;
            }
          }
        }

        // TOTAL ROW BLOCK
        &.total-sum-row {
          background-color: $light_blue;

          &:hover {
            background-color: $light_blue_hover;
          }

          &:not(.selected):hover {
            td.month_13 {
              background-color: $blue;
              color: white;
            }
          }

          &.name-row {
            background-color: $light_blue_hover;
          }
          td.month_13 {
            background-color: $light_blue_hover;
          }
        }

        td {
          padding: 8px 15px;

          .data-label {
            text-align: left;
            font-style: italic;
            b {
              margin-right: 5px;
            }
          }

          &.disabled {
            background-color: $light_gray;
          }

          &.month_13 {
            background-color: $light_blue;
          }
        }
      }
    }

    &.edit-mode {
      td.buget-item-row.editable:not(.month_13) {
        border: 1px solid $light_gray;

        &:not(.selectedCol, .selected) {
          background-color: $white;
        }

        &.disabled {
          background-color: $light_gray;
        }

        &:hover {
          box-shadow: inset -1px 0 0 $dark_gray, inset 0 -1px 0 $dark_gray, inset 1px 0 0 $dark_gray, inset 0 1px 0 $dark_gray;
        }

        .edit-cell {
          width: 100%;
          height: 22px;
          padding: 3px 8px;
          text-align: right;
          margin: -8px -15px;
          border-radius: 0px;
          border: none;
          margin-right: -14px;
        }
      }
    }
  }
}

tr.border-bottom .buget-item-row .edit-cell {
  border-bottom: 1px solid $light_gray;
}

td.month_12.buget-item-row .edit-cell {
  border-right: 1px solid $light_gray;
}

.table-container.copy-mode {
  td.buget-item-row.selectedCol {
    box-shadow: inset -1px 0 0 $green, inset 0 -1px 0 $green, inset 1px 0 0 $green, inset 0 1px 0 $green;
    border-color: $green !important;
  }

  th.selected {
    color: $black;
    font-weight: bold;
    box-shadow: inset -2px 0 0 $green, inset 0 -2px 0 $green, inset 2px 0 0 $green, inset 0 2px 0 $green;
    cursor: default;
  }

  th:not(.selected) {
    cursor: s-resize;
  }
}
