.main-container {
  .main-wrapper {
    display: flex;
    flex-direction: row;

    &.hide-sidebar {
      .sidebar {
        display: none;
      }
      .page-container {
        width: 100%;
      }
    }

    .page-container {
      height: 100vh;
      display: flex;
      flex-direction: row;
      width: calc(100% - 200px);
      overflow: hidden;

      .page-wrapper {
        background-color: $bg_gray;
        padding: 10px;
        overflow-y: auto;
        width: 100%;
      }
    }
  }
}
