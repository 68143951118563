.right-sidebar {
  border-left: 1px solid $border_color;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;
  height: 100vh;
  min-width: 220px;
  -webkit-transition: 0.3s ease-out;
  -moz-transition: 0.3s ease-out;
  -o-transition: 0.3s ease-out;
  transition: 0.3s ease-out;

  &.hide {
    min-width: 0px;
    width: 0px;
  }

  .sidebar-header {
    font-weight: bold;
    font-size: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
  }

  .filter-container {
    padding: 15px;
    margin-bottom: 0px;

    .filter-row {
      padding-bottom: 15px;
      align-items: center;

      input,
      select {
        width: 100%;

        &[type="checkbox"] {
          margin-left: 15px;
        }

        &[type="text"] {
          margin-left: 10px;
          height: 22px;
        }
      }
      select {
        margin-left: 10px;
      }

      label {
        width: 100%;
        margin-left: 10px;
        color: $dark_gray;
      }

      .btn-reset {
        margin-left: 15px;
        background-color: $light_gray;
        color: $black;
        height: 25px;
        width: 29px;
        border-radius: 25px;
        padding: 4px;
        margin-top: 2px;
        i {
          font-size: 15px;
        }
      }
    }
  }

  .sidebar-footer {
    padding: 15px;
    button {
      width: 100%;
      text-align: center;
      align-items: center;
      box-shadow: none;
      border: 1px solid $black;
      justify-content: center;
      font-size: 14px;
      font-weight: 300;

      &:hover {
        border: 1px solid $green;
      }
    }
  }
}
