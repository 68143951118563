html,
body {
  margin: 0;
  padding: 0;
  font-family: $font_body;
  color: $black;
  width: 100%;
  font-weight: 400;
  font-size: 14px;
}

h1 {
  font-family: $font_body;
}

h2,
h3 {
  font-family: $font_body;
  margin-top: 0px;
}

.subtitle {
  color: $color_subtitle;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 20px;
}

a,
a:visited {
  color: $green;
  text-decoration: none;
}

.cursor {
  cursor: pointer;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-60 {
  margin-top: 60px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-60 {
  margin-bottom: 60px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-30 {
  margin-left: 30px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-30 {
  margin-right: 30px;
}

.bold {
  font-weight: bold;
}

.pointer {
  cursor: pointer;
}

.btn-bar {
  display: flex;
  justify-content: space-between;
  min-height: 40px;

  button,
  .button {
    margin-right: 15px;
  }

  .btn-bar-left {
    display: flex;
  }

  .btn-bar-right {
    display: flex;
  }
}

@media screen and (max-width: $mobile_size) {
  .btn-bar {
    flex-direction: column;

    .btn-bar-right {
      margin-top: 10px;
    }
  }
}

.loading {
  margin-top: 10px;
}

.back-link,
.back-link:visited {
  color: $black;
  margin-bottom: 10px;
  display: block;
  display: inline-block;
  margin-right: 6px;
  top: 6px;
  position: relative;
}

.content-center {
  width: 100%;
  text-align: center;
}

.loader {
  display: flex;
  vertical-align: middle;
  flex-direction: row;
  align-items: center;

  img,
  i {
    width: 30px;
    margin-right: 15px;

    &.small {
      width: 20px;
    }
  }
}

.row {
  display: flex;
  flex-direction: row;
}

.col-2 {
  width: 50%;
}
