.navbar {
  display: flex;
  justify-content: space-between;

  &.navbar-admin-preview {
    padding-top: 10px;
  }

  .navbar-left {
    a {
      display: flex;
      vertical-align: middle;
      align-items: center;
      align-content: center;
      flex-direction: row;
      text-decoration: none;

      .navbar-logo {
        width: 30px;
        padding: 10px;
      }

      .navbar-logo-text {
        font-family: $font_title;
        color: $black;
        font-weight: bold;
        font-size: 20px;
      }
    }
  }

  .navbar-burger-content {
    display: none;
  }

  .navbar-right {
    display: flex;
    align-items: center;

    .navbar-btn {
      margin-right: 15px;
      a {
        color: $black;
        text-decoration: none;
        font-weight: bold;
      }
    }
  }
}

@media screen and (max-width: $tablet_size) {
  .navbar {
    z-index: 9;

    &.open {
      .navbar-left,
      .navbar-center,
      .navbar-burger-content {
        z-index: 99999;
      }
    }

    .navbar-burger-content {
      display: flex;

      .navbar-burger {
        width: 30px;
        cursor: pointer;
        margin-right: 15px;
      }
    }

    @-webkit-keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }

    .navbar-right {
      display: none;
      transition: opacity 0.1s ease-out;
      opacity: 0;
      -webkit-animation: fadeIn 0.1s;
      animation: fadeIn 0.1s;

      &.open {
        z-index: 999;
        opacity: 1;

        display: flex;
        position: absolute;
        top: 0px;
        background-color: white;
        width: calc(100% - 30px);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 15px;
        padding-top: 58px;
      }
      & > * {
        margin-top: 10px;
      }
    }
  }
}
