.table-container {
  width: 100%;
  overflow: scroll;
  box-shadow: 0 0 10px 3px #00000014;
  margin-top: 20px;
  border-radius: 10px;
  max-height: calc(100vh - 80px);
  background-color: $white;

  &.top-header {
    box-shadow: none;
    margin-top: 0px;
    max-height: calc(100vh - 120px);
  }
}

table {
  min-width: 100%;
  max-width: 100%;
  border-collapse: collapse;
  position: relative;
  background-color: $white;

  thead {
    background-color: $white;
    color: $black;
    font-weight: bold;
    margin-bottom: 5px;
    position: sticky;
    top: 0;

    th {
      padding: 2px 15px;
      padding-top: 5px;
      font-size: 13px;
      text-align: left;
      color: $dark_gray;
      font-weight: 400;
      cursor: pointer;
    }
  }

  tr {
    height: 35px;
    background-color: $white;

    transition-property: color, font-weight;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;

    &.loader-container {
      text-align: center;

      .loader {
        height: 100px;
        justify-content: center;
        padding-left: 30px;
      }
    }

    &:hover {
      background-color: $table_hover;
      .main-row {
        font-weight: 500;
      }

      td.disabled {
        background-color: $color_subtitle !important;
      }
    }

    td {
      line-break: anywhere;
      vertical-align: middle;
      white-space: nowrap;
      overflow: hidden;
      padding: 15px 15px;
      vertical-align: text-top;
      color: $table_text;

      a,
      a:visited {
        text-decoration: none;
        color: $table_text;
        position: relative;
      }

      time {
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  tfoot .table-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .page-control {
      display: flex;
      flex-direction: row;

      .page-counter {
        margin-right: 30px;
        align-content: center;
      }

      .page-size {
        align-content: center;
        select {
          margin-left: 10px;
        }
      }

      .page-items {
        margin-left: 30px;
        align-content: center;
      }
    }

    .pagination-list {
      display: flex;
      flex-direction: row;

      button {
        &:hover {
          border: 1px solid transparent !important;
          text-decoration: underline;
        }

        &.arrow {
          color: $light_gray;
          &:hover {
            color: $green;
            border: 1px solid transparent !important;
            text-decoration: none;
          }
        }
      }
    }
  }
}
