input,
select {
  -webkit-transition: border 0.3s ease-out;
  -moz-transition: border 0.3s ease-out;
  -o-transition: border 0.3s ease-out;
  transition: border 0.3s ease-out;

  width: max-content;
  height: 30px;
  padding: 3px 15px;
  border-radius: 9px;
  outline: none;
  border: 1px solid $light_gray;
  font-size: 16px;

  &:hover {
    border: 1px solid $black;
  }

  &:active,
  &:focus {
    border: 1px solid $green;
  }

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $light_gray;
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $light_gray;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $light_gray;
  }

  &[type="checkbox"] {
    width: 40px !important;
  }

  &.autocomplete__input[type="text"] {
    background-color: white;
    height: 38px;
    padding: 3px 15px;
    border-radius: 10px;
    outline: none;
    border: 1px solid $black;
    font-size: 16px;
    line-height: normal;
  }
}
select:invalid {
  color: $light_gray;
}

input:read-only {
  background-color: transparent;
  border-color: transparent;
  &:active,
  &:focus,
  &:hover {
    border-color: transparent;
  }
}

.autocomplete__search {
  background-color: white;
}

button,
.button {
  transition-property: color, background-color, border;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;

  color: $black;
  background-color: $white;
  border: none;
  font-family: $font_title;
  padding: 5px 15px;
  border-radius: 10px;
  height: 40px;
  width: fit-content;
  outline: none;
  cursor: pointer;
  box-shadow: 0 0 10px $light_gray;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 15px;
  border: 1px solid transparent;

  &:disabled {
    color: $light_gray !important;
    &:hover {
      color: $light_gray !important;
      border: 1px solid transparent !important;
      cursor: initial;
      text-decoration: none !important;
    }
  }

  &:hover {
    color: $green;
  }

  &.small {
    font-weight: normal;
    font-size: 10px;
    height: 20px;
    padding: 2px 7px;
  }

  &.medium {
    padding: 4px 15px;
    height: 35px;

    &.icon-right {
      padding-right: 4px;
    }
  }

  &.big {
    font-weight: bold;
    font-size: 1rem;
  }

  &.dark {
    color: $white;
    background-color: $black;
  }

  &.green {
    color: $white;
    background-color: $green;

    &:hover {
      color: $green;
      background-color: $white;
      border: 1px solid $green;
    }
  }

  &.red {
    color: $red;
    background-color: transparent;
    &:hover {
      border: 1px solid $red;
    }
  }

  &.link {
    color: $green;
    background-color: transparent;
    box-shadow: none;
    &:hover {
      border: 1px solid $green;
    }
  }
}

.meta {
  color: $table_hover;
}

.dropdown {
  .button {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding: 0px;
    padding-left: 15px;

    &.open {
      border-radius: 10px 10px 0px 0px;
    }

    & > div {
      padding: 0px;
      height: 20px;
      margin-right: 10px;
      font-family: Sansation;
      font-size: 15px;
      display: flex;
    }
  }
  .menu {
    padding: 10px;
    background-color: $table_hover;
    border-radius: 0px 0px 10px 10px;
    position: absolute;

    & > div {
      margin-bottom: 10px;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .dropdown-arrow {
    border-left: 1px solid;
    padding-left: 7px !important;
    height: auto !important;

    &:hover {
      opacity: 0.7;
    }
  }
}

.align-right {
  text-align: right;
  display: inline-block;
  input {
    text-align: right;
  }
}

.label-row {
  margin-bottom: 5px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  &.read {
    label {
      font-weight: bold;
      width: 150px;
    }
    & > div {
      display: inline-block;
    }
  }

  label {
    min-width: 200px;
    display: inline-block;
    margin-bottom: 5px;
    color: $dark_gray;
  }
  input,
  select {
    width: calc(100% - 30px);
  }

  .autocomplete-wrapper {
    width: 100%;

    * {
      z-index: 9999999999;
    }

    .wrapper {
      // height: 30px;
      // padding: 0px;
      border-radius: 9px;
      outline: none;
      border: 1px solid $light_gray;
      font-size: 16px;

      &:hover {
        border: 1px solid $black;
        box-shadow: none;
      }
    }
  }

  &.vertical-top {
    align-items: flex-start;
  }

  .content-checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

@media screen and (max-width: $mobile_size) {
  .label-row {
    box-sizing: border-box;

    label {
      width: fit-content;
      display: block;
    }

    input,
    select {
      display: block;
      max-width: none;
      box-sizing: border-box;
      height: 40px;
    }

    .alert-error {
      margin-top: -10px;
    }
  }
}

.info-icon-container {
  display: inline-block;
  cursor: pointer;

  .info-icon {
    width: 18px;
    margin-left: 10px;
  }

  .info-icon-text {
    display: none;
    position: absolute;
    background-color: $table_hover;
    border-radius: 5px;
    padding: 5px 10px;
    max-width: 300px;
    color: $black;
    margin-left: 35px;
    margin-top: -35px;
  }

  &:hover,
  &:active {
    .info-icon-text {
      display: block;
    }
  }
}

.alert-success {
  background-color: $light_green;
  border-radius: 10px;
  padding: 5px 10px;
  color: $white;
  margin-bottom: 20px;
  width: fit-content;
}

.alert-warn {
  background-color: $orange;
  color: $white;
  border-radius: 10px;
  padding: 5px 10px;
  margin-bottom: 20px;
  width: fit-content;
}

.alert-error {
  background-color: $red;
  color: $white;
  border-radius: 10px;
  padding: 5px 10px;
  margin-bottom: 20px;
  width: fit-content;
}

.message,
.error {
  color: $black;
}

.filter-container {
  margin-bottom: 20px;
  .filter-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;

    .filter-label {
      width: 150px;
      position: relative;
      bottom: -6px;
    }

    // .filter-data {
    //   select {
    //     width: 150px;
    //   }
    // }
  }
}

// Autocomplete

.suggestion-auto {
  display: block;
  border-radius: 7px;
  position: relative;
  transition: background 0.3s ease;

  .form-control-auto {
    input {
      height: 38px;
      width: 100%;
    }
  }

  .suggestions {
    position: absolute;
    top: 38px;
    left: 0px;
    z-index: 999;
    cursor: pointer;
    width: 100%;
    max-height: 200px;
    overflow: auto;

    & > div {
      padding: 15px 25px;
      transition: all 0.1s ease;
      background-color: $white;
      color: $black;
      border: 1px solid $black;
      border-top: none;

      &:hover {
        background-color: $table_hover;
      }
    }
  }
}

.currency {
  text-align: right;
  padding-right: 5px;
}

.popover {
  box-shadow: 0 1px 5px #0003, 0 2px 2px #00000024, 0 3px 1px -2px #0000001f;
  border-radius: 10px;
  z-index: 9999999;
  background-color: $white;
  padding: 10px;
  position: absolute;
  left: 280px;
  top: 140px;

  .popover-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 10px;
    cursor: pointer;
    input[type="checkbox"] {
      height: 20px;
      margin-left: 0px;
      margin-right: 0px;
    }
  }
}

.inline-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
}
