.login-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;

  .pwd-wrapper {
    position: relative;
    display: inline-block;
    input {
      padding-right: 40px;
    }
    .pwd-btn {
      position: absolute;
      top: 0;
      right: 0;
      border: none;
      background-color: transparent;
      cursor: pointer;
      border: none;
      box-shadow: none;
    }
  }

  .login-logo {
    margin-top: 100px;
    margin-bottom: 50px;
    width: 300px;
  }

  .login-form {
    display: flex;
    align-items: center;
    flex-direction: column;

    input {
      margin-bottom: 15px;
      min-width: 300px;
    }
  }
}
